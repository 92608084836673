import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert, DropdownButton, Dropdown, Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/gold3.css'
import '../style/sty.scss'
import moment from 'moment'
import getData from '../params/getdata'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
let abu = "#909090"
let cmain = 'black'
let black = 'rgb(38,38,38)'

let id = 'wendy-waldy'
let inisial_co = 'Wendy'
let inisial_ce = 'Waldy'

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/23/2021"

let modal = pw(id, "IMG_1837.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/UVNfUx5zNBB8nfhj8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MzVmNHVxcTdhcXQzMm40anNhaHNkODgzaTQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com  '
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.014665818753!2d106.68364011476928!3d-6.261797895467586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb9e836f533b%3A0x5bfb92326eb55f21!2sRumah%20Bapak!5e0!3m2!1sen!2sid!4v1609845976120!5m2!1sen!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            friend: 'Alone',
            comment:[]
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData(`kepada:"${id}"`)
            

            this.setState({ comment: comment.reverse() })
            console.log(comment)
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio("https://my.possiblewedding.com/wp-content/uploads/2021/01/Melee-Built-To-Last-Video.mp3");
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    dropdown = () => {
        let { friend } = this.state
        let item = []
        // for (let index = 1; index < 3; index++) {
        //     item[index] = (<Dropdown.Item onClick={() => {
        //         this.setState({ friend: index })
        //     }}>{index}</Dropdown.Item>)
        // }
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'Alone' })
        }}>Alone</Dropdown.Item>)
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'Partner' })
        }}>Partner</Dropdown.Item>)
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: '#86835d' }} className="mb-0 w-100 text-center">
                        How will you attend
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    render() {
        let font = 'taken_by_vultures_demoregular'
        let fontsub = 'Josefin Sans'
        let { hadir, days, hours, minutes, comment, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "1_compressed.jpg",
            "2_compressed.jpg",
            "3_compressed.jpg",
            "4_compressed.jpg",
            "5b_compressed.jpg",
            "6_compressed.jpg",
            "7_compressed.jpg",
            "8_compressed.jpg",
            "9_compressed.jpg",
            "DSCF0139.JPG",
            "DSCF0246.JPG",
            "DSCF0363.JPG",
            "DSCF0408.JPG",
            "DSCF0470.JPG",
            "DSCF0519.JPG",
            "DSCF8713.JPG",
            "DSCF8785.JPG",
            "DSCF8902.JPG",
            "DSCF9002.JPG",
            "DSCF9037.JPG",
            "DSCF9145.JPG",
            "DSCF9172.JPG",
            "DSCF9204.JPG",
            "DSCF9211.JPG",
            "DSCF9233.JPG",
            "DSCF9278.JPG",
        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.001&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Qwigley&display=swap" rel="stylesheet"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet"></link>
                </Helm>
                <style jsx>
                    {
                        `
                    h1{
                        font-family:'Qwigley', cursive!important;
                    }
                    `
                    }
                </style>

                <div id='gold5' style={{
                    backgroundImage: `none`,
                    backgroundColor: '#C5B39B',
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url('${(gambar(pw(id, "modal.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.001&w=1440&h=720'))}')`
                        }}>
                            <Item>
                                <Col xs={10} md={6} className='pt-3'>
                                    <img className='img-fluid w-100 p-md-5'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        <br />  {query ? <>Kepada Yth : {query}</> : ''} <br /></h2>
                                }</Item>
                            <Item>
                                <Row className=' position-absolute' style={{ bottom: '10%' }} >
                                    <Item>
                                        <Button onClick={() => { this.play() }}
                                            style={{ backgroundColor: 'transparent', borderColor: 'white', color: 'white' }}
                                        >
                                            Lihat Pengumuman
                            </Button >
                                    </Item>
                                </Row>
                            </Item>
                        </Container>
                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>

                                <div id="devi-johan">
                                    <Container className="py-3 ">
                                        <Item>
                                            <h1 className="w-100 text-center" style={{ color: cmain, fontFamily: font }} data-aos="fade-right" >
                                                “Yang abadi, di tengah yang bersemi”
                                        </h1>

                                            <p className="text-center px-3" style={{ color: cmain, fontFamily: fontsub }} data-aos="fade-left">
                                                “Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia”
<br />
                                                <b>(Matius 19:6)</b>
                                            </p>
                                        </Item>



                                        <Item>
                                            <p className='text-center p-2 px-4 ' style={{ color: cmain }} data-aos="fade-right">
                                                🎵 “I've looked for love in stranger places
                                            <br />But never found someone like you
                                            <br />Someone who's smile
                                            <br />Makes me feel I've been holding back
                                            <br />And now there's nothing I can do” 🎵
                                            </p>

                                        </Item>

                                    </Container>
                                    <Container className="p-3" >
                                        <div style={{ backgroundColor: 'white', borderRadius: '10px' }} className="p-3">
                                            <h1 data-aos="fade-right">
                                                Pernikahan
                                    </h1>
                                            <Row>
                                                <Col md={4} data-aos="fade-right">
                                                    <h1>
                                                        Waldy
                                    </h1>
                                                    <Item>
                                                        <img src={pw(id, "Gambar1.jpg")} className="p-3 w-50 img-fluid" />

                                                    </Item>

                                                    <p data-aos="fade-right">
                                                        Waldy Rodiardo Purba
                                    </p>
                                                    <p data-aos="fade-right">
                                                        Anak dari J. Purba (†) & M. Saragih (†)
                                    </p>
                                                </Col>
                                                <Col md={4} className="py-md-5">
                                                    <h1 className="py-3" data-aos="fade-right">
                                                        Dengan

                                    </h1>
                                                </Col>
                                                <Col md={4}>

                                                    <h1 data-aos="fade-left">
                                                        Wendy

                                    </h1>
                                                    <Item>
                                                        <img src={pw(id, "Gambar2.jpg")} className="p-3 w-50 img-fluid" />
                                                    </Item>
                                                    <p data-aos="fade-left">
                                                        Wendy Amelia Sihombing

                                    </p>
                                                    <p data-aos="fade-left">Anak dari J.E Sihombing & Mardiana Dana

                                    </p>
                                                </Col>
                                            </Row>
                                            <p data-aos="zoom-in">
                                                🎵 “Cause you are the sun in my universe, Consider the best when we felt the worstBut most of all. Most of all it's built to last”🎵


                                                </p>




                                        </div>

                                    </Container>
                                    <Container className="py-3">
                                        <h1 data-aos="fade-right">
                                            Merajut Rasa
                                    </h1>
                                        <p data-aos="fade-right">
                                            Delapan tahun kami bersama dalam suka, dan duka. Dengan restu dari seluruh keluarga besar terkasih, kami melangsungkan Pernikahan.

                                    </p>
                                        <p data-aos="fade-left">
                                            Berkat anugerah Tuhan, Pemberkatan Pernikahan dan Pernikahan Adat Batak dilaksanakan di Gereja HKBP Jatiwaringin, Jakarta Timur, pada hari Sabtu, tanggal 23 Januari 2021, dihadiri keluarga terkasih kami.

                                    </p>
                                        <p data-aos="fade-right">
                                            Dalam sukacita dan kegembiraan ini, kami tidak lupa akan rekan, sahabat, beserta handai taulan.

                                    </p>
                                        <p data-aos="fade-left">
                                            Terimakasih banyak untuk kalian yang  telah menjadi bagian perjalanan merajut rasa ini. Kami berharap agar Saudara/i sekalian turut merasakan kebahagiaan kami.

                                    </p>

                                        <p data-aos="zoom-in">
                                            🎵“All of our friends saw from the start
                                        <br />So why didn't we believe it too?
                                        <br />Now look where we are
                                        <br />You're in my heart now
                                        <br />And there's no escaping it for you !🎵
                                        <br />
                                        </p>
                                    </Container>
                                    <Container className="p-3">
                                        <h1 data-aos="fade-right">
                                            Intimate Wedding Reception
                                    </h1>
                                        <div style={{ marginTop: '-50px' }}>
                                            <Timer cmain={cmain} waktunikah={waktunikah} />
                                        </div>

                                    </Container>
                                   



                                    {/* <Container className="text-center dinny">
                                        <Item>
                                            <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                                <h1 className="fs30"
                                                    style={{


                                                        color: cmain
                                                    }}>
                                                    Covid-19
                          </h1>
                                                <Item>
                                                    <Col xs={12}>
                                                        <p className="cblack text-center w-100 fs16">
                                                            It’s important to us that everyone stays safe, please read carefully our Wedding Day Covid-19 Guidance before attending the event:
                                                    </p>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw('asset', 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                All participants must carry and wear a face mask during the wedding event
                        </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw('asset', 'salaman.png')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                Avoid handshake and use Namaste as an alternative to greet other guest.
                      </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw('asset', 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                Observe and keep a distance of 2 meter from other
                      </p>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={6} sm={4}>
                                                        <img src={pw("reni-shidqi", 'hand.svg')} className="w-100 img-fluid p-sm-4" />
                                                        <Row>
                                                            <p className="cblack text-center w-100 fs16">
                                                                Bring your own hand sanitizer and use it before and after make a physical contact.
                      </p>
                                                        </Row>
                                                    </Col>

                                                </Item>
                                            </Col>
                                        </Item>

                                        <Item>
                                            <Col>
                                            </Col>

                                        </Item>

                                    </Container>
                                     */}
                                    {/* <Container id='sectiongold56'>
                                        <div className='pt-3'>

                                            <div data-aos={`fade-right`} data-aos-duration="2000">
                                                <Item>
                                                    <div className='kotak col-10' style={{ backgroundColor: 'white' }}>
                                                        <Item>
                                                        <h1 className="w-100 text-center" style={{ color: cmain, fontSize:'2.5rem' }} >
                                                        Live moment
                                        </h1>
                                                            <p className='text-center p-2 px-4 fs14' style={{ color: cmain }}>
                                                                
Help us capture the best moment in our wedding day with the following hashtag in your social media post:<br />
#DevibelongstoGouw
</p>
                                                        </Item>
                                                    </div>

                                                </Item>
                                            </div>
                                        </div>
                                    </Container> */}


                                    <Container className='mt-3 py-3' data-aos="fade-right"
                                        data-aos-duration="1000"
                                        style={{ backgroundColor: 'white' }}
                                    ><h1 data-aos="fade-right">
                                            Menjadi rumah sepanjang usia
                                    </h1>
                                        <Slider slide={slider} />


                                        <p className="pt-5" data-aos="fade-right">
                                            “Dan diatas semuanya itu: kenakanlah kasih sebagai pengikat yang mempersatukan dan menyempurnakan”
                                        <br />
Kolose 3:14

                                        </p>
                                    </Container>
                                    <Container className="py-3">
                                        <p data-aos="fade-right">
                                            Mengingat himbauan pemerintah dan kondisi pandemi Covid-19 saat ini, dengan segala kerendahan hati, kami memohon maaf tidak dapat mengundang seluruh rekan, sahabat dan handai taulan pada acara bahagia kami.

                                        </p>
                                        <p data-aos="fade-right">
                                            Kami mohon kiranya kebahagiaan dan kabar gembira ini dapat dirasakan seluruh Guru, rekan, sahabat, serta handai taulan, dan bilamana berkenan memberikan Ucapan dan Doa Restu pada kolom komentar di bawah ini:
                                        </p>
                                        <Container className="text-center p-3">
                                        <Item>
                                            <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                                <h1 className="fs30"
                                                    style={{
                                                        color: cmain
                                                    }}>
                                                    Ucapan dan Doa
                          </h1>
                                                
                                                <Container id='sectiongold58' className="py-3" >

                                                    <div className='pt-3'>

                                                        <Item>
                                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                                <Item>
                                                                    <h1 className="w-100 text-center" style={{
                                                                        fontFamily: '"Marck Script", cursive',
                                                                        color: cmain
                                                                    }}>
                                                                        Ucapan Doa dan Restu
                                                     </h1>
                                                                </Item>
                                                                <Item>
                                                                    <form className="col-12 w-100">
                                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Your name :" name='nama' />
                                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Wishes" name='pesan' />


                                                                        <Item>
                                                                            <Col xs={12} className=''>
                                                                                {
                                                                                    submitted == true ? (
                                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                            Pesan anda sudah disampaikan
                                                                                        </Alert>) : (submitted === false ? (
                                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                                {
                                                                                                    err.map(val => {
                                                                                                        return (
                                                                                                            <li>{val}</li>
                                                                                                        )
                                                                                                    })
                                                                                                }

                                                                                            </Alert>
                                                                                        ) : false)
                                                                                }

                                                                            </Col>
                                                                        </Item>
                                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>

                                                                    </form>
                                                                </Item>
                                                            </div>
                                                        </Item>
                                                    </div>

                                                </Container>
                                                <Container>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                console.log(v)
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-left my-auto" 
                                                            style={{ fontSize: '18px',color:'white' }}>
                                                                <b>{decodeURI(v.dari)}</b><br />
                                                                {decodeURI(v.pesan)}<br />
                                                                <span  style={{ fontSize: '14px'}}>
                                                                {moment(v.createdAt).startOf('hour').fromNow()}
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>
                                    </Container>


                                            </Col>
                                        </Item>

                                        <Item>
                                            <Col>
                                            </Col>

                                        </Item>

                                    </Container>


                                        <p className="pt-3" data-aos="fade-right">
                                            Kami yang berbahagia,<br />
                                            <br />
                                            <br />

							Waldy & Wendy

                                        </p>

                                        <p data-aos="fade-right">
                                            <b>Lagu Melee - Built to last </b>
                                        </p>
                                        <p data-aos="fade-right">
                                            Lagu ini merupakan lagu favorit, yang dapat menggambarkan perjalanan cinta kasih kami. Kami berharap Pernikahan kami dapat berbahagia, bersama menapaki kehidupan, menjadi tempat ternyaman untuk melabuhkan seluruh rasa.

                                        </p>

                                    </Container>


                                    <Foot ig={pw("asset", "logoig-black.svg")} dark />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

